<template>
  <CommonModal ref="root">
    <!-- 开屏vip运营弹窗 -->
    <div class="activity-modal-vip" :style="{ '--background-image': `url(${IMAGE_URLS.VIP_MODAL.BACKGROUND})` }">
      <div @click="handleCloseModal" class="activity-modal-vip-close">
        <!-- <img :src="IMAGE_URLS.VIP_MODAL.CLOSE_ICON" /> -->
      </div>
      <div class="activity-modal-vip-bottom">
        <div class="activity-modal-vip-code">
          <img :src="qrCodeURL" alt="">
          <!-- <span>微信扫码 立享优惠</span> -->
        </div>
      </div>
    </div>
  </CommonModal>
</template>
<script>
import { computed, ref, onBeforeMount, toRefs } from 'vue'
import store from '@/store'
import CommonModal from '@/components/modal/common/component.vue'
import { format } from 'date-fns'
import { getCarplayInfo } from '@/service/carplay-info'
import useQRCode from '@/composables/useQRCode'
import Toast from '@/utils/toast'
import get from 'lodash/get'
import { sendLog } from '@/directives/v-log/log'
import { vipLogFrom } from '@/constants/index'
import IMAGE_URLS from '@/constants/images/index'

export default {
  name: 'ActivityVipModal',
  components: {
    CommonModal,
  },
  props: {
    closeEvent: {
      type: Function,
      default: () => {
      }
    },
    times: {
      type: Number,
      default: 1
    }
  },
  setup(props) {
    const root = ref(null)
    const { times } = toRefs(props)
    const appStartTime = computed(() => store.state.appStartTime)
    const userType = computed(() => store.state.userInfo.userType)

    const { getQRCodeURL } = useQRCode()
    let qrCodeURL = ref(IMAGE_URLS.VIP_MODAL.DEFAULT_QR_CODE)

    // const bg_img = computed(() => store.state.activityInfo.openscreen_vip.img)
    // const bg_img = ref('https://qncweb.ktvsky.com/20240201/vadd/8cb1e645b74bfd8c7f61fade5fc8a18a.png')

    const getVipQrcode = async () => {
      const { data } = await getCarplayInfo()
      if (get(data, 'pay_qr', '')) {
        const qrCodeData = await getQRCodeURL(`${data.pay_qr}&fr=520&log=${vipLogFrom.get(`开屏弹窗${times.value == 2 ? 2 : ''}`)}&ut=${userType.value}`)
        if (qrCodeData) {
          qrCodeURL.value = qrCodeData
        }
        return
      }
      Toast('未获取到登录二维码')
    }

    const handleCloseModal = () => {
      const now = Date.now()
      props.closeEvent.call()
      sendLog({
        event_type: 'show',
        event_name: 1723,
        event_data: {
          start_time: format(appStartTime.value, 'yyyy-MM-dd HH:mm:ss'),
          end_time: format(now, 'yyyy-MM-dd HH:mm:ss'),
          stay_time: Math.round((now - appStartTime.value) / 1000),
          key_words: '销售VIP',
          type: 3
        }
      })
      root.value.hide()
    }

    onBeforeMount(getVipQrcode)

    return {
      root,
      qrCodeURL,
      handleCloseModal,
      IMAGE_URLS,
    }
  }
}
</script>
<style lang="stylus" scoped>
.activity-modal-vip
  padding-top 122px
  position relative
  width 1000px
  height 730px
  background var(--background-image) no-repeat
  background-size 100% 100%
  color rgba(255, 51, 100, 1)
  display flex
  flex-direction column
  align-items center
  &-close
    // width 66px
    // height 66px
    width 32px
    height 32px
    position absolute
    // top 100px
    // right -30px
    top 30px
    right 30px
    left auto!important
  &-bottom
    width 100%
    height 193px
    display flex
    justify-content center
    position absolute
    bottom 40px
    left 0
  &-code
    position absolute
    // bottom 228px
    // right 493px
    bottom 264px
    right 190px
    width 162px
    height 162px
    display flex
    justify-content center
    align-items center
    background #fff
    border-radius 10px
    img
      width 160px
      height 160px
      border-radius 5px
      margin-top 0!important
    span
      height 21px
      line-height 21px
      font-weight 400
      font-size 15px
      color rgba(0, 0, 0, 0.8)
      margin-top 8px
@media screen and (max-width: 1200px)
  .activity-modal-vip
    zoom 0.7

</style>
