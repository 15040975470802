<template>
  <div class="app-main" :class="isVersion2 ? 'version2' : ''">
    <!-- <template v-if="$route.name != 'home'"> -->
    <router-view v-show="mvIsHide" v-slot="{ Component }">
      <keep-alive>
        <component
          :key="$route.meta.key"
          v-if="$route.meta.keepAlive"
          :is="Component"
        />
      </keep-alive>
    </router-view>
    <router-view v-show="mvIsHide" v-if="!$route.meta.keepAlive" />
    <!-- </template> -->
    <!-- <Index v-show="$route.name == 'home'" /> -->
    <!--    <SearchBarTips />-->
    <BottomBar />
    <MvCore :isShowMiniByRouteName="isShowMiniByRouteName" />
    <!--    <MvPageComponent v-show="!mvIsHide" />-->
    <VantStyle></VantStyle>
    <OrderSongControlPopup></OrderSongControlPopup>
    <AIfaceOrderControlPopup></AIfaceOrderControlPopup>
    <FreeSinging></FreeSinging>
    <ClimaxModal></ClimaxModal>
    <PhoneSong v-show="mvIsHide && $route.name !== 'album'" />
  </div>
</template>

<script>
import {
  onBeforeMount,
  watch,
  computed,
  provide,
  onMounted,
  onBeforeUnmount,
  ref,
} from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import useM3u8 from "@/composables/useM3u8";
import useActivity from "@/composables/useActivity";
import useNats from "@/composables/useNats";
import {
  getOrderedList,
  getAlreadyList,
  setLoginSendVipActivityTag,
  getLoginSendVipActivityTag,
  getMicActivityShowTag,
  getVipActivityShowTag,
  getSearchCache,
  getSearchSong,
  setVipExpireTag,
  getVipExpireTag,
  setFreeSongNoTimeShow,
  getFreeSongNoTimeShow,
} from "@/utils/historyCache";
import { Dialog } from "vant";
import Toast from "@/utils/toast";
import MvCore from "@/components/mv-core/index.vue";
import OrderSongControlPopup from "@/components/order-song-control-popup/index.vue";
import AIfaceOrderControlPopup from "@/components/ai-face-order/index.vue";
import { sendLog } from "@/directives/v-log/log";
import { getRecommendSongList } from "@/service/recommendSong";
import { setUid } from "@/utils/bl";
import { format } from "date-fns";
// import MvPageComponent from '@/components/mv/index.vue';
import SearchBarTips from "@/components/search-bar/tips.vue";
import BottomBar from "@/components/bottom-bar/index.vue";
import formatStr from "@/constants/index";
import get from "lodash/get";
import store2 from "store2";
import { initNatsClient } from "@/packages/nats";
import useGlobalEvent from "@/composables/useGlobalEvent";
import useForceLogin from "@/composables/useForceLogin";
import useQuality from "@/composables/useQuality";
import useVip from "@/composables/useVip";
import { vipLogFrom } from "@/constants/index";
import FloatingAI from "@/components/ai/floating.vue";
import FreeSinging from "@/components/free-singing/index.vue";
import ClimaxModal from "@/components/climax-modal/index.vue";
import { getFreeVip } from "@/service/user";
import eventBus from "@/utils/event-bus";
// import Index from "@/pages/index";
import PhoneSong from "@/components/phone-song/index.vue";

export default {
  name: "App",
  components: {
    MvCore,
    OrderSongControlPopup,
    AIfaceOrderControlPopup,
    // MvPageComponent,
    SearchBarTips,
    BottomBar,
    FloatingAI,
    FreeSinging,
    ClimaxModal,
    // Index,
    PhoneSong,
  },
  setup() {
    let appLoading = ref(false);
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const { addCurrSongM3u8 } = useM3u8();
    const {
      showActivityModal,
      showActivityVip,
      showVipExpire,
      showActivityMic,
      showActivityRecommendSong,
      showActivitySignIn,
      showActivityFreeSongNoTime,
    } = useActivity();
    const { showForceLogin } = useForceLogin();
    const { handleReceiveMessageFromNats } = useNats();
    const { installGlobalClickHandler, uninstallGlobalClickHandler } =
      useGlobalEvent();
    const { showVipQrcode } = useVip();

    const setting = computed(() => store.state.setting);
    const isLogin = computed(() => !!userInfo.value.unionid);
    const userInfo = computed(() => store.state.userInfo);
    const vipInfo = computed(() => store.state.vipInfo);
    const macAddress = computed(() => store.state.macAddress);
    const natsConfig = computed(() => store.state.base.nats);
    const isForceLogin = computed(() => store.state.isForceLogin);

    let checkIsWatchLoginSendVip = ref(false);
    let FNTInstance = ref(null);
    const showFreeSongNoTimeModal = ref(null);

    const loginSendVip = computed(() => store.state.activityInfo.loginSendVip);
    const openscreen_vip = computed(
      () => store.state.activityInfo.openscreen_vip.id
    );
    const hasMic = computed(() => store.state.hasMic);
    const isShowSignIn = computed(() => store.state.signin.isShowSignIn);

    const orderedSongIdMap = computed(() => store.state.orderedSongIdMap);
    provide("orderedSongIdMap", orderedSongIdMap);
    const curPlayingSongid = computed(
      () => store.state.videoPlayerHistory.songItem.songid
    );
    provide("playingSongId", curPlayingSongid);

    const videoPlayer = computed(() => store.state.videoPlayerHistory);
    const mvIsHide = computed(() => store.state.mvIsHide);
    const orderedListNumber = computed(() => store.state.orderedList.length);

    const { useMvQuality } = useQuality();
    const resolution = computed(() => store.state.carplayInfo.resolution);
    const isVip = computed(() => !!store.state.vipInfo.end_time);
    const isVersion2 = computed(
      () =>
        route.name === "home" ||
        route.name === "search" ||
        route.name === "singer" ||
        route.name === "mine" ||
        route.name === "profile" ||
        route.name === "setting" ||
        route.name === "agreementUser" ||
        route.name === "agreementPrivacy" ||
        route.name === "vipExchange" ||
        route.name === "singing" ||
        route.name === "songList"
    );

    const limitRouteName = [
      "home",
      "search",
      "singer",
      "songList",
      "mine",
      "profile",
    ];
    const isShowMiniByRouteName = computed(() => {
      // console.log('route.name', route.name)
      return limitRouteName.includes(route.name);
    });

    // watch(isShowMiniByRouteName, (val) => {
    //   console.log('isShowMiniByRouteName', val)
    // })

    const freeVipNumber = computed(() => store.state.freeVipNumber);

    let appStartTime = Date.now();

    const initApp = () => {
      store.dispatch("getSetting");
      store.dispatch("getMacAddress");
      store.dispatch("getSystemInfo");
      store.dispatch("signin/setSignInActivityStatus");
      store.dispatch("act/setActList");

      store.dispatch("startCheckLoginKeepStatus");
      store.dispatch("startCheckAIface");
      store.dispatch("base/getBaseInfo");
      store.dispatch("base/getConfig");
      store.dispatch("base/reportSession");
      store.dispatch("abTest/setABTestVersion");
      // 初始化特斯拉top50常唱数据
      store.dispatch("oftenSing/initTop50List");
      store.dispatch("climax/fetchClimaxList");
      store.dispatch("miniMv/fetchDefaultList");
      // 启动上报
      sendLog({
        event_type: "custom",
        event_name: 241,
        event_data: {
          _car_language: navigator.language,
          _car_time: Date.now(),
          memory_max: get(performance, "memory.jsHeapSizeLimit", 0),
          memory_used: get(performance, "memory.usedJSHeapSize", 0),
        },
      });
      // app打开上报
      sendLog({
        event_type: "custom",
        event_name: 221,
        event_data: {
          start_time: format(appStartTime, formatStr),
        },
      });
      appLoading.value = true;
      store.commit("UPDATE_APP_START_TIME", appStartTime);
      // 查看运营活动
      store.dispatch("setActivityInfo");

      // console.log('app init sss', !!store2('freeSing'))
      store.dispatch("climax/updateFreeSing", !!store2("freeSing"));
      store.dispatch("recommend/initTop100List");

      store.dispatch("miniMv/setMuted", !store.state.isFirstSong);
    };

    const onUnload = () => {
      //方案一：只在这里存储一次 貌似车机不太行 换即时存储
      // setOrderedList()
      // setAlreadyList()

      if (appStartTime) {
        const unloadTime = Date.now();
        const stayTime = Math.round((unloadTime - appStartTime) / 1000);
        sendLog({
          event_type: "custom",
          event_name: 222,
          event_data: {
            start_time: format(appStartTime, formatStr),
            end_time: format(unloadTime, formatStr),
            stay_time: stayTime,
          },
        });
      }
    };

    // 监听网络状态 先这样写 后面再看有没有更好的方式
    const onNetChange = () => {
      // console.log(e)
      Toast("当前网络状态差，请检查网络状态");
    };

    const setFreeVip = async () => {
      const res = await getFreeVip({
        unionid: userInfo.value.unionid,
      });
      store.commit("SAVE_FREE_VIP_COUNT", res.data.free_vip_count);
    };
    watch(appLoading, (val) => {
      if (val) {
        const appLoadingDom = document.getElementById("app-load");
        if (appLoadingDom) appLoadingDom.remove();
      }
    });

    watch(isLogin, (val) => {
      if (!val) {
        if (setting.value.quality === "1080") {
          store.dispatch("saveSetting", {
            ...setting.value,
            quality: "720",
          });
        }
        store.dispatch("collect/resetUserCollectList");
        // 重置0元购活动状态
        store.dispatch("zerobuy/resetZerobuyStatus");
        if (isShowSignIn.value) store.dispatch("signin/resetSignInData");
        Dialog.confirm({
          className: "global-force-login",
          confirmButtonText: "我知道了",
          showCancelButton: false,
          message: "监测到您的账号在当前车机已下线",
          beforeClose: () => {
            showForceLogin();
            return true;
          },
        }).then(() => {
          router.push({
            name: "home",
          });
        });
        setUid();
        // 退出登录时重置常唱数据
        store.dispatch("oftenSing/resetOftenSingList");

        // 获取3天限时vip免费活动字段 - 下线活动
        // store.dispatch("act/resetVipFreeActivity");
      } else {
        sendLog({
          event_type: "10000~50000",
          event_name: 30238,
          event_data: {
            str1: "通用",
            str2: "登录",
            str3: "扫码登录",
          },
        });
        if (!isVip.value) {
          setFreeVip();
        }
        store.dispatch("singTime/initSingTaskTime", userInfo.value.unionid);
        // 0元购活动任务及签到状态
        store.dispatch("zerobuy/updateZerobuyStatus", userInfo.value.unionid);
        store.dispatch("zerobuy/updateSigninStatus", userInfo.value.unionid);
        // 收藏功能下线
        // store.dispatch('collect/getUserCollectList', userInfo.value.unionid)
        if (isShowSignIn.value)
          store.dispatch("signin/setSignInData", userInfo.value.unionid);

        // 过期会员提醒 - 检测到登录用户会员过期时弹运营弹框 - 每天只弹一次
        if (vipInfo.value.expire && getVipExpireTag()) {
          setVipExpireTag();
          showVipExpire({
            closeEvent: () => {
              store.dispatch("getCarplayInfo");
            },
            onCancel: () => {
              store.dispatch("getCarplayInfo");
            },
          });
          sendLog({
            event_type: "show",
            event_name: 1752,
          });
        }

        // 登录时更新常唱数据
        store.dispatch("oftenSing/initOftenSingList", userInfo.value.unionid);

        // 登录时更新云端画质到本地(非vip云端画质还为1080时，降级为720)
        let qualityNew =
          !isVip.value && resolution.value === "1080"
            ? "720"
            : resolution.value;
        if (!qualityNew) qualityNew = "480"; // 云端检测是否是新用户未设置过画质，新用户设置480
        useMvQuality(qualityNew);

        // 获取3天限时vip免费活动字段 - 下线活动
        // store.dispatch("act/getVipFreeActivity", userInfo.value.unionid);
      }
      // 检测到已登录时正常开启活动领取状态检测
      setTimeout(() => {
        checkIsWatchLoginSendVip.value = val;
      }, 500);
    });

    // 已登录用户初始uid赋值
    watch(
      userInfo,
      (val) => {
        if (val.unionid) {
          setUid(val.unionid);
        }
      },
      {
        deep: true,
        immediate: true,
      }
    );

    // nats配置
    watch(
      natsConfig,
      (val) => {
        if (val) {
          const { eip } = val;
          if (eip && macAddress.value) {
            initNatsClient({ ...val, mac: macAddress.value }, (msg) => {
              return handleReceiveMessageFromNats(msg);
            });
          }
        }
      },
      {
        deep: true,
        immediate: true,
      }
    );

    const openRecommendSongModal = async () => {
      console.log("openRecommendSongModal");
      // 已点无歌时显示
      if (orderedListNumber.value) return;

      // 无开屏歌单时不显示
      let songList = await getRecommendSongList();
      if (!songList.length) return;

      // 每日一次 可配置7天不显示
      const isFirst = get(store2("recommendSongRule"), "showTime", "");
      const currDate = format(Date.now(), "yyyy-MM-dd");
      if (isFirst === currDate) return;
      const dateNow = new Date();
      const noShowRule = new Date(
        get(store2("recommendSongRule"), "noShowTime", "2023-06-28")
      );
      if (dateNow < noShowRule) return;

      sendLog({
        event_type: "show",
        event_name: 3001,
        event_data: {
          str1: "1",
        },
      });
      // 开屏推荐歌曲（处于开屏显示优先级低，已点无歌时显示）
      showActivityRecommendSong({ songList });
    };

    // 开屏麦克风商城弹窗逻辑
    const openMicMallModal = (activityShowTag) => {
      // 每天第一次打开弹 一天只弹一次 云控执行活动规则
      const isShow = getMicActivityShowTag();
      if (isShow) {
        console.log("首次启动~");
        // 麦克风开屏弹窗下线
        // if (!hasMic.value) {
        //   console.log('no mic')
        //   showActivityMic()
        //   sendLog({
        //     event_type: 'show',
        //     event_name: 1723,
        //     event_data: {
        //       start_time: format(appStartTime, formatStr),
        //       end_time: format(appStartTime, formatStr),
        //       stay_time: 0,
        //       key_words: '销售麦克风'
        //     }
        //   })
        // } else {
        //   openRecommendSongModal()
        // }
        // openRecommendSongModal() 下线开屏歌曲推荐

        // 开屏签到弹框
        if (isShowSignIn.value) {
          showActivitySignIn();
          sendLog({
            event_type: "show",
            event_name: 1728,
            event_data: {
              str1: "弹窗展示次数",
            },
          });
        }
      }
    };

    // 设置活动标记 超过三次不显示领取弹框
    const setActivityTag = () => {
      // 初始未登录并默认打开了活动弹框时 关闭弹框后取消检测失败的场景 避免错误场景提示
      checkIsWatchLoginSendVip.value = false;
      const currActTag = getLoginSendVipActivityTag();
      if (currActTag < 3) setLoginSendVipActivityTag(currActTag + 1);
    };

    // 检测车机是否已参与领取会员活动
    watch(loginSendVip, (val, old) => {
      // 会员领取关闭应用前只主动弹一次
      if (val === 1 && old === undefined) {
        if (getLoginSendVipActivityTag() > 2) {
          return;
        }
        checkIsWatchLoginSendVip.value = true; // 打开弹框 检测活动领取状态
        showActivityModal({
          closeEvent: setActivityTag,
        });
      }

      // 检测到账号或者车机已领取过
      if (val === 4 && old === 1) {
        Toast({
          message: `会员领取成功！有效期至${
            vipInfo.value?.end_time.split(" ")[0]
          }`,
          duration: 5000,
        });
      }

      if (!checkIsWatchLoginSendVip.value) return;

      // 检测到账号或者车机已领取过
      if ([2, 3].includes(val) && old === 1) {
        Toast({
          message: "对不起，您的账号已经领取过了，不可以再领取了！",
          duration: 5000,
        });
      }
    });

    // 检测车机是否是持麦账号 没有则显示开屏麦克风活动弹窗
    // watch(hasMic, (val) => {
    //   // console.log('hasMic', val)
    //   openMicMallModal(val)
    // })

    // 开屏弹窗活动云控
    watch(openscreen_vip, (val) => {
      console.log("openscreen_vip", val);
      openMicMallModal(val === 0 ? true : !!val);
    });

    watch(mvIsHide, (v) => {
      // 进入过mv页面后默认初始化自动播放
      if (!v) {
        if (!store.state.videoInitAutoPlay) {
          store.commit("UPDATE_MV_INIT_AUTOPLAY", 1);
        }
        if (curPlayingSongid.value && !videoPlayer.value.songItem.hls) {
          addCurrSongM3u8(videoPlayer.value.songItem);
        }
      }
    });

    // 监测songid变化 请求歌曲资源 - 转到全局去处理 预防黑屏情况
    watch(curPlayingSongid, (val) => {
      if (val) {
        addCurrSongM3u8(videoPlayer.value.songItem);
      }
      // console.log('showFreeSongNoTimeModal', showFreeSongNoTimeModal.value);

      if (showFreeSongNoTimeModal.value) {
        const vipNoTimeShow = getFreeSongNoTimeShow();
        // console.log('showFreeSongNoTimeModal:videoPlayer.value.songItem.is_vip', videoPlayer.value.songItem.is_vip);
        // console.log('showFreeSongNoTimeModal:vipNoTimeShow', vipNoTimeShow);
        // console.log('showFreeSongNoTimeModal:isVip.value', isVip.value);
        // videoPlayer.value.songItem.is_vip &&
        if (!vipNoTimeShow && !isVip.value) {
          // Side Effects
          showFreeSongNoTimeModal.value = false;
          FNTInstance.value = showActivityFreeSongNoTime();
          setFreeSongNoTimeShow(true);
        }
      }
    });

    watch(freeVipNumber, (val, old) => {
      console.log("freeVipNumber", val, old);
      if (val === 0 && old === 1) {
        const vipNoTimeShow = getFreeSongNoTimeShow();
        if (!vipNoTimeShow && !isVip.value) {
          // Side Effects
          console.log("showFreeSongNoTimeModal~");
          showFreeSongNoTimeModal.value = true;
          // FNTInstance.value = showActivityFreeSongNoTime()
          // setFreeSongNoTimeShow(true)
        }
      }
    });

    onBeforeMount(initApp);

    const determineUserStatus = () => {
      if (vipInfo.value.end_time) {
        return "vip"; // VIP用户
      } else if (userInfo.value.unionid) {
        return "loggedInButNotVip"; // 非VIP登录用户
      }
      return "notLoggedIn"; // 未登录用户
    };

    // 强制登录
    const forceLoginLogic = async () => {
      await store.dispatch("getCarplayInfo");

      setTimeout(() => {
        showForceLogin();
      }, 600);

      const userStatus = determineUserStatus();
      const times = getVipActivityShowTag(userStatus);
      if (times) {
        if (times === 1) {
          showActivityVip({ times });
        } else {
          showVipQrcode({
            log: "开屏弹窗2",
            isLogin: false,
            fr: vipLogFrom.get("开屏弹窗2"),
          });
        }
        sendLog({
          event_type: "10000~50000",
          event_name: times === 2 ? 30181 : 30180,
          event_data: {
            str1: `开屏弹窗${times}`,
            str2: "弹窗",
            str3: "二维码展示",
            str4: "show",
          },
        });
        sendLog({
          event_type: "show",
          event_name: 1723,
          event_data: {
            start_time: format(appStartTime, formatStr),
            end_time: format(appStartTime, formatStr),
            stay_time: 0,
            key_words: "销售VIP",
            type: 3,
          },
        });
      }
    };

    onMounted(() => {
      //初始化挂载本地历史已点已唱
      const [orderedList, alreadyList, searchCache, searchSong] = [
        getOrderedList(),
        getAlreadyList(),
        getSearchCache(),
        getSearchSong(),
      ];
      // 需求 - 每次进入应用暂时不同步已点数据
      // if (orderedList.length) {
      //   store.commit('SAVE_ORDERED_LIST', orderedList);
      //   // 此处只挂载歌曲id 歌曲不在此处挂载 车机上的浏览器和实际浏览器在- mv自动播放表现会不一致
      //   store.commit('SAVE_ORDERED_SONGIDMAP', orderedList[0]['songid']);
      // }
      if (alreadyList.length) {
        store.commit("SAVE_ALREADY_LIST", alreadyList);
      }
      if (searchCache.length) {
        store.dispatch("search/updateSearchCache", searchCache);
      }
      if (searchSong.length) {
        store.dispatch("search/initSearchSong", searchSong);
      }

      store.commit("INIT_AI_FACE");

      forceLoginLogic();
    });

    const handleShowVipQrcode = (params) => {
      showVipQrcode(params);
    };

    onMounted(() => {
      window.addEventListener("beforeunload", onUnload);
      // plusReady(netChange)
      window.addEventListener("offline", onNetChange);
      installGlobalClickHandler();

      eventBus.on("show-vip-qrcode", handleShowVipQrcode);
    });
    onBeforeUnmount(() => {
      window.removeEventListener("beforeunload", onUnload);
      window.removeEventListener("offline", onNetChange);
      uninstallGlobalClickHandler();

      eventBus.off("show-vip-qrcode", handleShowVipQrcode);
    });

    return {
      mvIsHide,
      isVersion2,
      route,
      isShowMiniByRouteName,
    };
  },
};
</script>

<style lang="stylus">
.app-main
  position relative
  &.version2
    background: radial-gradient(121% 203.64% at 95% 9%, #ffb3ff -3%, #697cff 31%, #a1efff 49%, #007c88 79%, #00172e 90%)
    .bottom-bar
      background: #FFFFFF1A
      border: 1px solid #FFFFFF26
      border-radius 10px
      backdrop-filter: blur(150px);
</style>
