<template>
  <div class="song">
    <div v-if="renderType === 'list'" class="song-item" :class="className">
      <div class="left" :class="{ ordered: isOrdered }" @click="handleOrder">
        <div class="name">
          <!-- <span class="name-txt">{{ songItem.music_name }} </span> -->
          <span class="name-txt" v-html="highlightedName"></span>
          <!-- <span class="vip" v-if="songItem.is_vip">VIP</span> -->
        </div>
        <div class="desc">
          <template v-if="songItem.singer">
            <span
              class="author"
              :class="singerCanClick && 'clickable'"
              @click="handleClickSinger"
              >{{ songItem.singer }}</span
            >
          </template>
          <img
            v-if="songItem.is_vip"
            class="song-block-vip"
            src="https://qncweb.ktvsky.com/20231206/vadd/56dc0bc6477bf2c7a6c4fcdc8360804e.png"
            alt=""
          />
          <i v-else class="free-icon">免费</i>
          <img
            v-if="show1080Icon"
            class="icon-1080"
            src="https://qncweb.ktvsky.com/20240305/other/865db1ca5d7ce94af8cc4ae48d874879.png"
            alt=""
          />
          <img
            v-if="showNewSongIcon"
            class="icon-newsong"
            src="https://qncweb.ktvsky.com/20240325/vadd/6387b6385220cb3385dba761ab5aac23.png"
            alt=""
          />
          <span class="sing-cnt" v-if="songItem.sing_cnt"
            >演唱过 <span>{{ songItem.sing_cnt }}</span> 次</span
          >
        </div>
      </div>
      <div class="right">
        <svg
          @click.stop="handlePlay('play')"
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.9" clip-path="url(#clip0_31_907)">
            <circle
              cx="25.7222"
              cy="14.2403"
              r="9.27787"
              stroke="white"
              style="stroke: white; stroke-opacity: 1"
              stroke-width="2"
            />
            <path
              d="M17.1572 16.9525L5.44516 31.8946L8.67518 35.1246L23.6173 23.4126"
              stroke="white"
              style="stroke: white; stroke-opacity: 1"
              stroke-width="2"
            />
          </g>
          <defs>
            <clipPath id="clip0_31_907">
              <rect
                width="40"
                height="40"
                fill="white"
                style="fill: white; fill-opacity: 1"
              />
            </clipPath>
          </defs>
        </svg>
        <svg
          class="order-icon"
          @click.stop="handlePlay('order')"
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.9">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M19 21V35H21V21H35V19H21V5H19V19H5V21H19Z"
              fill="white"
              style="fill: white; fill-opacity: 1"
            />
          </g>
        </svg>
      </div>
    </div>
    <div
      v-else
      :class="{
        'song-block': true,
        className: !!className,
        ordered: isOrdered,
      }"
      @click="handleOrder"
    >
      <span class="name">{{ songItem.music_name }}</span>
      <div class="desc">
        <template v-if="songItem.singer">
          <span
            class="author"
            :class="singerCanClick && 'clickable'"
            @click="handleClickSinger"
            >{{ songItem.singer }}</span
          >
        </template>
        <div class="center">
          <img
            v-if="songItem.is_vip"
            class="song-block-vip"
            src="https://qncweb.ktvsky.com/20231206/vadd/56dc0bc6477bf2c7a6c4fcdc8360804e.png"
            alt=""
          />
          <i v-else class="free-icon">免费</i>
        </div>
        <span class="sing-cnt" v-if="songItem.sing_cnt"
          >演唱过 <span>{{ songItem.sing_cnt }}</span> 次</span
        >
      </div>

      <svg
        v-if="isShowPlayBtn"
        class="song-block-play"
        @click.stop="handlePlay('play')"
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.9" clip-path="url(#clip0_31_907)">
          <circle
            cx="25.7222"
            cy="14.2403"
            r="9.27787"
            stroke="white"
            style="stroke: white; stroke-opacity: 1"
            stroke-width="2"
          />
          <path
            d="M17.1572 16.9525L5.44516 31.8946L8.67518 35.1246L23.6173 23.4126"
            stroke="white"
            style="stroke: white; stroke-opacity: 1"
            stroke-width="2"
          />
        </g>
        <defs>
          <clipPath id="clip0_31_907">
            <rect
              width="40"
              height="40"
              fill="white"
              style="fill: white; fill-opacity: 1"
            />
          </clipPath>
        </defs>
      </svg>
      <svg
        v-if="isShowOrderBtn"
        class="song-block-order"
        @click.stop="handlePlay('order')"
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.9">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M19 21V35H21V21H35V19H21V5H19V19H5V21H19Z"
            fill="white"
            style="fill: white; fill-opacity: 1"
          />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
import { computed, inject, toRefs } from "vue";
import { useStore } from "vuex";
import useSongItem from "@/composables/useSongItem";
import useOrder from "@/composables/useOrder";
import useVip from "@/composables/useVip";
// import { Toast } from 'vant'
// import { postSongCollect, deleteSongCollect } from '@/service/collect'
import { sendLog } from "@/directives/v-log/log";
import get from "lodash/get";
import { useRouter, useRoute } from "vue-router";
import split from "lodash/split";

export default {
  name: "SongItem",
  props: {
    className: {
      type: String,
      default: "",
    },
    songItem: Object,
    index: Number,
    startPosition: {
      type: Number,
      default: 0,
    },
    logFrom: {
      type: Object,
      default: () => {},
    },
    ponitActionLog: {
      type: Object,
      default: () => {},
    },
    singerEnable: {
      // mv页情况下，歌手名点击是否可以使用
      type: Boolean,
      default: true,
    },
    renderType: {
      default: "list", // list:列表形态 block：块状形态
      type: String,
    },
    isStickButton: {
      default: false,
      type: Boolean,
    },
    show1080Icon: {
      default: false,
      type: Boolean,
    },
    showNewSongIcon: {
      default: false,
      type: Boolean,
    },
    isShowPlayBtn: {
      default: false,
      type: Boolean,
    },
    isShowOrderBtn: {
      default: false,
      type: Boolean,
    },
    keyword: {
      type: String,
      default: "",
    },
  },
  emits: ["singer-click", "order-click"],
  setup(props, { emit }) {
    const router = useRouter();
    const route = useRoute();
    const {
      songItem,
      logFrom,
      keyword,
      ponitActionLog,
      singerEnable,
      show1080Icon,
      showNewSongIcon,
    } = toRefs(props);
    const store = useStore();
    const orderedSongIdMap = inject("orderedSongIdMap");
    const { orderSong } = useSongItem();
    const { stickSongToTop } = useOrder();
    const { showVipQrcode } = useVip();
    // const orderedList = computed(() => store.state.orderedList)
    const { orderSong: immediatePlay, orderedList } = useOrder();
    const orderedListNumber = computed(() => orderedList.value.length);
    const userInfo = computed(() => store.state.userInfo);
    const vipInfo = computed(() => store.state.vipInfo);
    const isOrdered = computed(() => {
      return orderedSongIdMap.value[songItem.value.songid];
    });
    // const collectList = computed(() => store.state.collect.collectList)
    const mvIsHide = computed(() => store.state.mvIsHide);
    const singerCanClick = computed(
      () =>
        (mvIsHide.value && !route.query.singerid) ||
        (!mvIsHide.value && singerEnable.value)
    );

    // 歌曲数据源不同，这块逻辑组件内部处理
    // const collectIcon = [
    //   'https://qncweb.ktvsky.com/20230718/vadd/64a23b873deec37e5eeccd4239af4e10.png',
    //   'https://qncweb.ktvsky.com/20230717/vadd/a3826d976f35f4794a4fa74ea8b6d09c.png',
    // ]
    // const collectStatus = ref(0)

    // const setCollectStatus = (val) => {
    //   if (val.length) {
    //     let isCollect = false
    //     isCollect = val.some(v => v.songid === songItem.value.songid && !v.noCollect)
    //     collectStatus.value = isCollect ? 1 : 0
    //   }
    // }
    // onMounted(() => {
    //   setCollectStatus(collectList.value)
    // })

    // watch(collectList, (val) => {
    //   setCollectStatus(val)
    // })

    // const handleCollect = async () => {
    //   sendLog({
    //     event_type: 'click',
    //     event_name: 131,
    //     event_data: {
    //       type: 4,
    //       song_name: songItem.value.music_name,
    //       song_id: String(songItem.value.songid),
    //       singer: String(songItem.value.singer),
    //     }
    //   })
    //   if (!userInfo.value.unionid) {
    //     showVipQrcode()
    //     return
    //   }
    //   const res = await postSongCollect({
    //     unionid: userInfo.value.unionid,
    //     songid: songItem.value.songid,
    //   })
    //   if (res && res.errcode === 200) {
    //     Toast('歌曲已加入收藏') // res.errmsg
    //     collectStatus.value = collectStatus.value === 0 ? 1 : 0
    //     store.dispatch('collect/pushNewSongToCollect', songItem.value)
    //   } else {
    //     Toast('收藏失败')
    //   }
    // }

    // const handleUnCollect = async () => {
    //   if (!userInfo.value.unionid) {
    //     showVipQrcode()
    //     return
    //   }
    //   const res = await deleteSongCollect({
    //     unionid: userInfo.value.unionid,
    //     songid: songItem.value.songid,
    //   })
    //   if (res.errcode === 0) {
    //     Toast('歌曲已取消收藏') // res.errmsg
    //     collectStatus.value = collectStatus.value === 0 ? 1 : 0
    //     store.dispatch('collect/deleteSong', songItem.value)
    //   } else {
    //     Toast('取消收藏失败')
    //   }
    // }

    // 处理同一事件既有点歌又有置顶的情况
    const filterPALog = (type) => {
      if (get(ponitActionLog.value, "event_type") !== "10000~50000") return {};
      const eventAction = [
        "进入常唱",
        "进入歌名点歌",
        "进入任意歌手",
        "歌曲列表",
      ];
      let res = {
        ...ponitActionLog.value,
      };
      if (eventAction.includes(get(res, "event_data.str3"))) {
        if (type === "order") {
          res.event_data.str5 = "点歌";
          res.event_data.str6 = "click";
        } else if (type === "stick") {
          res.event_data.str7 = "置顶点歌";
          res.event_data.str8 = "click";
        }
      }
      return res;
    };

    const handleOrder = (
      _,
      v = {
        type: "order",
      }
    ) => {
      // console.log('handleOrder', v.type, v.type === 'order')
      if (v.type === "order") emit("order-click");
      const filterPALogData = filterPALog("order");
      store.commit("UPDATE_CONTROL_FROM_TYPE", 1);
      const isValid1080p = [20179, 3211].includes(logFrom.value.song_list_id);
      if (Object.keys(orderedSongIdMap.value).length === 0) {
        orderSong(songItem.value, {
          from: logFrom.value,
          ponitActionLog: filterPALogData,
          isValidNewSong: showNewSongIcon.value,
          isValid1080p,
          isNotSingImmediately: v.type === "order",
        });
        return;
      }
      orderSong(songItem.value, {
        immediate: false,
        from: logFrom.value,
        ponitActionLog: filterPALogData,
        isValidNewSong: showNewSongIcon.value,
        isValid1080p,
        isNotSingImmediately: v.type === "order",
      });
    };

    const handleStickSongToTop = () => {
      const filterPALogData = filterPALog("stick");
      store.commit("UPDATE_CONTROL_FROM_TYPE", 1);
      if (Object.keys(orderedSongIdMap.value).length === 0) {
        orderSong(songItem.value, {
          from: logFrom.value,
          ponitActionLog: filterPALogData,
        });
      } else {
        orderSong(songItem.value, {
          immediate: false,
          from: logFrom.value,
          ponitActionLog: filterPALogData,
        });
      }
      stickSongToTop();
    };

    const handleClickSinger = (e) => {
      if (!singerCanClick.value) {
        return;
      }
      e.stopPropagation();
      sendLog({
        event_type: "10000~50000",
        event_name: 10108,
        event_data: {
          str1: "任意点歌页",
          str2: "歌曲列表",
          str3: "点击任意歌手",
          str4: "click",
        },
      });
      if (mvIsHide.value) {
        router.push({
          name: "songList",
          query: {
            name: split(songItem.value.singer, ",")[0],
            image: songItem.value.singer_head,
            singerid: songItem.value.singerid,
          },
        });
      } else {
        emit("singer-click", {
          singer: split(songItem.value.singer, ",")[0],
          singerhead: songItem.value.singer_head,
          singerid: songItem.value.singerid,
        });
        sendLog({
          event_type: "10000~50000",
          event_name: 10109,
          event_data: {
            str1: "MV点歌页",
            str2: "歌曲列表",
            str3: "点击任意歌手",
            str4: "click",
          },
        });
      }
    };

    const handlePlay = async (type) => {
      // console.log('order', type)
      await Promise.all([
        handleOrder(null, {
          type: type,
        }),
      ]);
      if (type === "play") {
        console.log("play");
        sendLog({
          event_type: "10000~50000",
          event_name: 30239,
          event_data: {
            str1: "通用",
            str2: "立即演唱",
            str3: "点击",
            str4: "click",
          },
        });
        immediatePlay(songItem.value, orderedListNumber.value - 1);
      }
    };

    const highlightedName = computed(() => {
      console.log("keyword", keyword.value);
      if (!keyword.value) return songItem.value.music_name;
      const regex = new RegExp(`(${keyword.value})`, "gi");
      return songItem.value.music_name.replace(
        regex,
        '<span class="highlight" style="color: rgba(219, 174, 106, 1);">$1</span>'
      );
    });

    return {
      isOrdered,
      userInfo,
      vipInfo,
      highlightedName,
      // collectStatus,
      // collectIcon,
      // handleCollect,
      // handleUnCollect,
      handleOrder,
      handleStickSongToTop,
      handleClickSinger,
      singerCanClick,
      handlePlay,
    };
  },
};
</script>

<style lang="stylus" scoped>
.song
  height fit-content
  &-item
    display flex
    align-items center
    justify-content space-between
    height 141px
    color #ffffff
    border-bottom 1px solid rgba(255, 255, 255, 0.18)
    // border-radius 10px
    color rgba(255,255,255,0.7)
    .left
      flex 1
      display flex
      flex-direction column
    .name
      font-size 32px
      color rgba(255,255,255,0.7)
      margin-bottom 15px
      display flex
      align-items center
      &-txt
        display -webkit-box
        -webkit-box-orient vertical
        -webkit-line-clamp 2
        overflow hidden
        word-break break-all
        max-width 812px
        .highlight
          color rgba(219, 174, 106, 1)
        @media screen and (max-width 1200px)
          max-width 690px
          font-size 26px
      // .vip
      //   display flex
      //   justify-content center
      //   align-items center
      //   width 64px
      //   height 36px
      //   font-size 22px
      //   border 3px solid #F0D290
      //   color #F0D290
      //   border-radius 8px
      //   margin-left 16px
    .desc
      display flex
      align-items center
      height 33px
      font-size 24px
      color rgba(255,255,255,0.4)
      @media screen and (max-width 1200px)
        font-size 20px
      .clickable
        position relative
        padding-right 26px
        &::after
          content ""
          position absolute
          right 0
          top 50%
          margin-top -10px
          width 22px
          height 22px
          background url('https://qncweb.ktvsky.com/20231212/vadd/70dbe52816b882ae1f6871b3a509f375.png') no-repeat
          background-size 100% 100%
      .divider
        margin -4px 16px 0 16px
      .sing-cnt
        margin-left 16px
        span
          color #DBAE6A
      .author
        max-width 320px
        overflow hidden
        white-space nowrap
        text-overflow ellipsis
        @media screen and (max-width 1200px)
          max-width 160px
    .ordered
      .name
        color rgba(219, 174, 106, 1) !important
      .desc
        color rgba(219, 174, 106, 1) !important
      .clickable::after
        content ""
        background-image url('https://qncweb.ktvsky.com/20231212/vadd/4a5a96b4a7e0b0ae7f364679f5f69417.png')
    .right
      display flex
      align-items center
      margin-right 20px
      svg
        width 40px
        height 40px
        &:first-child
          margin-right 80px
        @media screen and (max-width 1200px)
          width 40px
          height 40px
          font-size 22px
        &:active
          color rgba(219, 174, 106, 0.8)
          border 2px solid rgba(219, 174, 106, 0.80)
          background rgba(219, 174, 106, 0.10)
    @media screen and (max-width 1200px)
      .name
        &-txt
          font-size: 26px;
      .desc
        font-size: 20px;
  &-block
    width 560px
    height 160px
    display flex
    flex-direction column
    justify-content center
    padding 0 40px
    margin-bottom 40px
    background rgba(255, 255, 255, 0.10)
    border-radius 10px
    position relative
    &-play
      position absolute
      top 47.5px
      right 132px
      width 40px
      height 40px
    &-order
      position absolute
      top 47.5px
      right 32px
      width 40px
      height 40px
    @media screen and (max-width: 1200px)
      padding 0 20px
    .name
      // height 38px
      // line-height 38px
      font-size 32px
      font-weight 500
      margin-bottom 10px
      color rgba(255, 255, 255, 1)
      overflow hidden
      white-space nowrap
      text-overflow ellipsis
      max-width 500px
      @media screen and (max-width: 1200px)
        max-width 690px
        margin-bottom 0
    .desc
      display flex
      align-items center
      flex-wrap wrap
      height 28px
      font-size 24px
      color rgba(153, 153, 153, 1)
      .author
        max-width 320px
        overflow hidden
        white-space nowrap
        text-overflow ellipsis
        @media screen and (max-width 1200px)
          max-width 160px
      span
        height 28px
        line-height 28px
      .clickable
        position relative
        padding-right 26px
        &::after
          content ""
          position absolute
          right 0
          top 50%
          margin-top -10px
          width 22px
          height 22px
          background url('https://qncweb.ktvsky.com/20231212/vadd/70dbe52816b882ae1f6871b3a509f375.png') no-repeat
          background-size 100% 100%
      .divider
        margin -4px 16px 0 16px
        color rgba(255, 255, 255, 1)
        opacity 0.4
      .center
        display flex
        align-items center
        margin-right 16px
      .sing-cnt
        span
          color #DBAE6A
    &-vip
      width 50px
      height 26px
      margin-left 13px
      @media screen and (max-width 1200px)
        width 42.3px
        height auto
        margin-left 8px
  .icon-1080
    width 72px
    margin-left 12px
  .free-icon
    width 56px
    height 26px
    display flex
    align-items center
    justify-content center
    background: linear-gradient(90deg, #009BCB -0.89%, #3D73FF 99.11%);
    color #fff
    font-size 18px
    border-radius 100px
    margin-left 11px
  .icon-newsong
    width 102px
    margin-left 12px
  .ordered
    .name
      color rgba(219, 174, 106, 1) !important
    .desc
      color rgba(219, 174, 106, 1) !important
    .divider
      color rgba(219, 174, 106, 1) !important
      opacity 1
    .clickable::after
      content ""
      background-image url('https://qncweb.ktvsky.com/20231212/vadd/4a5a96b4a7e0b0ae7f364679f5f69417.png')
  @media screen and (max-width 1200px)
    .divider
      margin-left 8px!important
      margin-right 8px!important
</style>
