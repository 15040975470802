// images resources
const IMAGE_URLS = {
  VIP_MODAL: {
    CLOSE_ICON:
      "https://qncweb.ktvsky.com/20240910/vadd/d4b941b2305173339042fe90d78e62bd.png",
    BACKGROUND:
      // "https://qncweb.ktvsky.com/20240920/vadd/c2ec09558fbd83cd43585d43b2e724d7.png",
      "https://qncweb.ktvsky.com/20240926/vadd/8c6666de7cf1b2c7c7f70971a8e72c6a.png",
    DEFAULT_QR_CODE:
      "https://qncweb.ktvsky.com/20211130/vadd/82b806b8bf560e49df69162835879c65.png",
  },
  // 可以继续添加其他模块的图片URL
};

export default IMAGE_URLS;
